<template>
  <div v-if="tabs && tabs.length" class="vaimo-tabs" :class="'view-' + view">
    <div
      v-if="!hideIfOne || (hideIfOne && tabs.length > 1)"
      class="vaimo-tabs__tabs-wrapper"
      :class="{ sticky: sticky, 'with-border-bottom': borderBottomVisible }"
    >
      <div class="vaimo-tabs__tabs">
        <div role="tablist" aria-label="Slider Tabs">
          <button
            v-for="(tab, index) in tabs"
            :id="`ID_tab${tab.id}`"
            :key="tab.id"
            ref="tabRefs"
            :title="tab.title"
            :aria-controls="tab.id"
            :aria-label="tab.title"
            :class="{ active: currentTab === index }"
            role="tab"
            :tabindex="currentTab === index ? '0' : '-1'"
            class="vaimo-tabs__tab"
            @click="changeTab(index)"
            @keydown="onKeyDown($event, index)"
          >
            <span>{{ tab.title }}</span>
          </button>
        </div>
      </div>
      <div class="vaimo-tabs__tabs-shader"></div>
      <div class="vaimo-tabs__tabs-shader opposite"></div>
    </div>

    <div v-if="view === 'tabs'" class="vaimo-tabs__content flex flex-col">
      <div
        v-for="(tab, index) in showedTabs"
        :id="`tab${index}`"
        :key="tab.id"
        :aria-labelledby="`ID_tab${tab.id}`"
        role="tabpanel"
        :class="{ 'invisible h-0 order-last': tab.id !== tabs[currentTab].id }"
      >
        <slot :name="tab.id"></slot>
      </div>
    </div>
    <div v-else-if="view === 'anchors'" class="vaimo-tabs__content">
      <div
        v-for="(tab, i) in tabs"
        :id="tab.id + '-content'"
        :key="tab.id"
        ref="tabContentRefs"
        :data-tab-id="tab.id"
        :data-tab-index="i"
        class="vaimo-tabs__content-part"
      >
        <slot :name="tab.id"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from '@nuxtjs/composition-api';
import { useIntersectionObserver } from '@vueuse/core';

export default {
  name: 'VaimoTabs',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    hideIfOne: {
      type: Boolean,
      required: false,
      default: true
    },
    sticky: {
      type: Boolean,
      required: false,
      default: false
    },
    borderBottomVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    activeTabIndex: {
      type: Number,
      required: false,
      default: 0
    },
    view: {
      type: String,
      required: false,
      validator(value) {
        return ['tabs', 'anchors'].includes(value);
      },
      default: 'tabs'
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const tabContentRefs = ref([]);
    const currentTab = ref(props.activeTabIndex);
    const tabRefs = ref([]);
    const showedTabs = computed(() => props.tabs);
    const changeTab = (index) => {
      emit('change', index);

      if (props.view === 'anchors') {
        scrollToTab(index);
      } else {
        nextTick(() => {
          currentTab.value = index;
          tabRefs.value[index]?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
          });
        });
      }
    };

    const scrollToTab = (index) => {
      const tab = props.tabs[index];
      if (!tab) return;

      const targetEl = tabContentRefs.value[index];
      if (targetEl) {
        targetEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    const onKeyDown = (event) => {
      const key = event.key;
      if (key === 'ArrowLeft' || key === 'ArrowRight') {
        const newIndex =
          key === 'ArrowLeft'
            ? (currentTab.value - 1 + props.tabs.length) % props.tabs.length
            : (currentTab.value + 1) % props.tabs.length;
        changeTab(newIndex);
        nextTick(() => tabRefs.value[newIndex]?.focus());
      }
    };

    onMounted(() => {
      changeTab(props.activeTabIndex);
      if (props.view === 'anchors') {
        handleIntersection();
        setTimeout(() => {
          currentTab.value = props.activeTabIndex;
        }, 100);
      }
    });

    const observers = ref([]);

    const handleIntersection = () => {
      props.tabs.forEach((el, index) => {
        const targetEl = tabContentRefs.value[index];
        if (targetEl) {
          const { stop } = useIntersectionObserver(
            targetEl,
            ([{ isIntersecting }]) => {
              if (isIntersecting) {
                if (currentTab.value !== index) {
                  currentTab.value = index;
                  emit('change', index);
                }
              }
            },
            { threshold: 0.25 }
          );
          observers.value.push(stop);
        }
      });
    };

    onBeforeUnmount(() => {
      observers.value.forEach((stop) => stop());
    });

    watch(
      () => props.activeTabIndex,
      (newIndex) => {
        changeTab(newIndex);
      }
    );

    return {
      tabContentRefs,
      currentTab,
      changeTab,
      showedTabs,
      onKeyDown,
      tabRefs
    };
  }
};
</script>

<style scoped lang="scss">
.vaimo-tabs {
  &__content {
    &-part {
      scroll-margin-block-start: 120px;
    }

    .vaimo-slider {
      min-height: 600px;

      @include for-desktop {
        min-height: 500px;
      }
    }
  }

  &__tabs-wrapper {
    @apply mb-base m-and-l:mb-md relative;

    &.sticky {
      @apply z-decoration;
      position: sticky;
      top: 82px;
      background: #fff;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 5px;
      border-top: 1px solid #e8e8e8;
      transition: top 0.3s;

      @include for-screen-m-and-l {
        top: 0;
      }
    }
    &.with-border-bottom {
      border-bottom: 1px solid #e8e8e8;
    }
  }

  &__tabs-shader {
    position: absolute;
    top: 0;
    width: 30px;
    height: 100%;
    background: -moz-linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgb(255 255 255) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
    &.opposite {
      right: 0;
      left: unset;
      transform: rotate(180deg);
    }
  }

  &__tabs {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    div {
      @apply flex pl-sm ml-auto mr-auto m-and-l:justify-center;
      width: fit-content;
      font-size: 16px;
      line-height: 135%;
      @include for-screen-s {
        font-size: 14px;
      }

      button {
        @apply whitespace-nowrap uppercase cursor-pointer;

        &:last-child {
          @apply s:mr-md;
        }

        span {
          @apply inline-block px-sm;
          font-weight: 300;
          color: #808080;
        }

        &.active span {
          color: #000;
          font-weight: 400;
        }

        &:focus {
          outline: none;
        }

        &:focus-visible {
          outline: 2px solid #000;
          outline-offset: -1px;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 20px;
      height: 100%;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.scroll-up {
  .vaimo-tabs__tabs-wrapper.sticky {
    transition: top 0.4s;
    top: 82px;
    @include for-screen-m-and-l {
      top: var(--sticky-header-height);
    }
  }

  .vaimo-tabs__tabs-border {
    transition: top 0.4s;
    top: 133px;
    @include for-screen-m-and-l {
      top: 118px;
    }
  }
}

.scroll-down {
  .vaimo-tabs__tabs-wrapper.sticky {
    transition: top 0.4s;
    top: 0;
    @include for-screen-m-and-l {
      top: 0;
    }
  }

  .vaimo-tabs__tabs-border {
    transition: top 0.4s;
    top: 51px;
    @include for-screen-m-and-l {
      top: 54px;
    }
  }
}
</style>
